exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-tags-page-tsx": () => import("./../../../src/templates/tags-page.tsx" /* webpackChunkName: "component---src-templates-tags-page-tsx" */)
}

